class ArrayProxy<T> extends Array<T> {
  private handler!: Handler

  constructor(...items: T[]) {
    super()
    this.push(...items)

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ArrayProxy.prototype)
  }

  addEventListener(handler: Handler) {
    this.handler = handler
  }

  public override push(...items: T[]): number {
    const res = super.push(...items)
    this.handler && this.handler('push', items)
    return res
  }
}

type Handler = (action: ArrayAction, items: any) => void

export type ArrayAction = 'push'

export default ArrayProxy
