import { randomKey, browser, Cookies } from '@kulee/helper'
import { ENV, HOST } from './constant'
import ArrayProxy, { ArrayAction } from './ArrayProxy'
import logger from './logger'

class Analytics {
  // 统计客户端信息cookie存储的key
  private static KA_COOKIE_KEY = 'ka_client'

  private env!: string
  private appId!: string
  private region = typeof Intl !== 'undefined' ? Intl.DateTimeFormat().resolvedOptions().locale : 'UNKNOWN'
  private kaClientData!: KAClientData

  constructor() {
    // 暴露到外面使用的变量，通过监听push来实现数据上报
    window.dataKule = new ArrayProxy()

    // 添加push的事件捕获
    window.dataKule.addEventListener((action: ArrayAction, items: any[]) => {
      if (action === 'push') {
        this.handleData(items)
      }
    })

    // 页面加载完成后自动上报用户信息
    window.addEventListener('load', () => {
      window.dataKule.push('track', 'user', 'login')
    })
  }

  /**
   * 处理监听的数据
   * @param items 相关的数据
   */
  private handleData(items: any[]) {
    const [action, ...rest] = items

    // 配置
    if (action === 'config') {
      const [env, appId] = rest
      this.env = env
      this.appId = appId

      // 初始化客户端数据
      this.kaClientData = this.getClientData()
    }
    // 事件
    else if (action === 'track') {
      const [eventType, eventAction, eventProperty] = rest
      this.send(eventType, eventAction, eventProperty)
    } else {
      logger.warn('Invalid event.')
    }
  }

  /**
   * 上报数据
   * @param eventType 事件类型（事件名称），如：user
   * @param eventAction 事件动作，如：login
   * @param eventProperty 事件标签值，如: {userId: xxx}
   */
  private send(eventType: string, eventAction: string, eventProperty: any = '') {
    if (!this.env || !this.appId) {
      logger.error('use window.dataKule.push("config", env, appId) to config analytics first!')
      return
    }

    try {
      const eventPropertyString = typeof eventProperty === 'string' ? eventProperty : JSON.stringify(eventProperty)
      const img = new Image()
      img.onload = () => {
        logger.info('send data success')
      }
      img.onerror = error => {
        logger.warn('send data fail', error)
      }

      img.src = `${HOST[this.env] || HOST[ENV.PRODUCTION]}?APP_ID=${
        this.kaClientData.appId
      }&EVENT_TYPE=${eventType}&EVENT_ACTION=${eventAction}&EVENT_PROPERTY=${window.encodeURI(
        eventPropertyString
      )}&LANG=${this.kaClientData.lang}&PLATFORM=${this.kaClientData.platform}&SDK_VERSION=${
        this.kaClientData.sdkVersion
      }&USER_KEY=${this.kaClientData.userKey}&REGION=${this.kaClientData.region}&t=${new Date().getTime()}`
    } catch (error) {
      logger.error('event send fail', error)
    }
  }

  /**
   * 从本地获取客户端数据
   * @returns 客户端数据
   */
  private getClientDataFromLocal(): KAClientDataFromLocal | null {
    try {
      const clientData = JSON.parse(Cookies.get(Analytics.KA_COOKIE_KEY) || '')
      if (clientData && clientData.userKey) {
        return clientData
      }

      return null
    } catch (error) {
      return null
    }
  }

  /**
   * 存客户端数据到本地
   * @param clientDataLocal 客户端数据
   */
  private setClientDataToLocal(clientDataLocal: KAClientDataFromLocal) {
    try {
      Cookies.set(Analytics.KA_COOKIE_KEY, JSON.stringify(clientDataLocal))
    } catch (error) {
      logger.error('save client data to local error', error)
    }
  }

  /**
   * 获取客户端数据
   */
  private getClientData(): KAClientData {
    let clientBaseData = this.getClientDataFromLocal()
    if (!clientBaseData) {
      // 用户标识
      const userKey = randomKey(10) + new Date().getTime()
      // 客户端类型
      const platform = browser.version.android ? 1 : browser.version.ios ? 2 : 3
      clientBaseData = {
        userKey,
        platform
      }
      this.setClientDataToLocal(clientBaseData)
    }

    return {
      ...clientBaseData,
      appId: this.appId,
      lang: navigator.language,
      acceptLang: navigator.language,
      sdkVersion: '1.0.0',
      region: this.region
    }
  }

  public getKaClientData(): KAClientData {
    return this.kaClientData
  }

  public setKaClientData(data: KAClientData) {
    this.kaClientData = data
  }

  public setUserKey(userKey: string) {
    this.kaClientData.userKey = userKey
  }

  public setPlatform(platform: number) {
    this.kaClientData.platform = platform
  }
}

const analytics = new Analytics()
window.analytics = analytics

interface KAClientDataFromLocal {
  // 用户标识
  userKey: string
  // 客户端平台类型  1：安卓、2：ios、3：web
  platform: number
}

type KAClientData = KAClientDataFromLocal & {
  // APP ID
  appId: string
  // 浏览器语言
  lang: string
  // 访问语言
  acceptLang: string
  // SDK（或其他）版本号
  sdkVersion: string
  // 用户所在地区
  region: string
}

export default analytics
