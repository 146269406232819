import { Logger } from '@kulee/helper'

// 日志前缀
export const LOGGER_PREFIX = '__CLAZZ__'
// 日志输出等级
export const LOGGER_LEVEL = Logger.LEVEL.INFO

export const ENV = {
  DEVELOPMENT: 'development',
  TEST: 'test',
  PRODUCTION: 'production'
}

export const HOST = {
  [ENV.DEVELOPMENT]: 'http://tgadev.just4fun.asia/sb/tgaEtl/data/v1.jpg',
  [ENV.TEST]: 'https://data.just4fun.sg/sb/tgaEtl/data/v1.jpg',
  [ENV.PRODUCTION]: 'https://tga.just4fun.sg/sb/tgaEtl/data/v1.jpg'
}
